import AdultPHQ4 from '../ScreeningImplementations/AdultPHQ4/Form/AdultPHQ4';
import AdultNeeds from '../ScreeningImplementations/AdultNeeds/Form/AdultNeeds';
import AdultPrapareSdoh from '../ScreeningImplementations/AdultPrapareSdoh/Form/AdultPrapareSdoh';
import AdultEPPrapareSdoh from '../ScreeningImplementations/AdultEPPrapareSdoh/Form/AdultEPPrapareSdoh';
import AdultUCLA3 from '../ScreeningImplementations/AdultUCLA3/Form/AdultUCLA3';
import AdultWellBeingAssessment from '../ScreeningImplementations/AdultWellBeing/Form/AdultWellBeingAssessment';
import YouthNeeds from '../ScreeningImplementations/YouthNeeds/Form/YouthNeeds';
import YouthPHQ2 from '../ScreeningImplementations/YouthPHQ2/Form/YouthPHQ2';
import YouthUCLA3 from '../ScreeningImplementations/YouthUCLA3/Form/YouthUCLA3';
import { Screenings } from '../enumerations/enums';

export const Forms = {
  [Screenings.YOUTH_UCLA3]: YouthUCLA3,
  [Screenings.YOUTH_PHQ2]: YouthPHQ2,
  [Screenings.YOUTH_NEEDS]: YouthNeeds,
  [Screenings.ADULT_UCLA3]: AdultUCLA3,
  [Screenings.ADULT_PHQ4]: AdultPHQ4,
  [Screenings.ADULT_NEEDS]: AdultNeeds,
  [Screenings.ADULT_PRAPARE_SDOH]: AdultPrapareSdoh,
  [Screenings.ADULT_EP_PRAPARE_SDOH]: AdultEPPrapareSdoh,
  [Screenings.ADULT_WELLBEING]: AdultWellBeingAssessment,
};
