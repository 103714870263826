import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Core_Helpers/Singleton';
import { UserAccessor } from 'Core_Helpers/UserAccessor';
import { ScreeningScheduleApiDataService } from './services/ScreeningScheduleApiDataService';
import { browserHistory } from 'Core_Helpers/AppInsights';
import { HOME, ASSESSMENT } from 'Core_Pages/Routes/RoutesConfig';
import urlcat from 'urlcat';
import { WorkFlowTypes } from './workflows';
import { ScreeningScoresApiDataService } from './services/ScreeningScoresApiDataService';
import { ScreeningResponseApiDataService } from './services/ScreeningResponseApiDataService';
import { matchPath } from 'react-router';
import { Screenings, ScreeningType } from './enumerations/enums';
import { IScreeningTakerModuleStore } from './IScreeningTakerModuleStore';
import { IStateStore } from 'Core_Helpers/IStateStore';
import { ScreeningFormModuleStoreFactory } from './ScreeningFormModuleStoreFactory';

export class ScreeningModuleStore extends Singleton implements IStateStore {
  private screeningScoresApiDataService: ScreeningScoresApiDataService;
  private screeningResponseApiDataService: ScreeningResponseApiDataService;
  private screeningScheduleApiDataService: ScreeningScheduleApiDataService;
  private userAccessor: UserAccessor;
  private atomStore = getDefaultStore();
  private screeningResponseGuidAtom;
  private screeningGuidAtom;
  private screeningResponseGuidsAtom;
  private screeningResultsAtom;
  private workflowAtom;
  private dueScreeningsAtom;
  private loadingAtom;
  private highestScoreAtom;
  private resultsErrorAtom;
  private needsGuidAtom;
  private screeningErrorAtom;
  private dueScreeningsIndexAtom;
  private screeningForm: IScreeningTakerModuleStore;

  constructor() {
    super();

    this.screeningResponseApiDataService = new ScreeningResponseApiDataService();
    this.screeningScheduleApiDataService = new ScreeningScheduleApiDataService();
    this.screeningScoresApiDataService = new ScreeningScoresApiDataService();
    this.userAccessor = new UserAccessor();
    this.dueScreeningsAtom = atom([]);
    this.screeningResponseGuidAtom = atom(null);
    this.screeningResponseGuidsAtom = atom([]);
    this.screeningGuidAtom = atom(null);
    this.workflowAtom = atom(WorkFlowTypes.QUESTIONNAIRE);
    this.screeningResultsAtom = atom([]);
    this.loadingAtom = atom(false);
    this.highestScoreAtom = atom(null);
    this.resultsErrorAtom = atom(false);
    this.needsGuidAtom = atom(null);
    this.screeningErrorAtom = atom(false);
    this.dueScreeningsIndexAtom = atom(0);
  }

  public Use = () => {
    useAtom(this.screeningResponseGuidAtom);
    useAtom(this.screeningResponseGuidsAtom);
    useAtom(this.screeningGuidAtom);
    useAtom(this.workflowAtom);
    useAtom(this.screeningResultsAtom);
    useAtom(this.dueScreeningsAtom);
    useAtom(this.loadingAtom);
    useAtom(this.highestScoreAtom);
    useAtom(this.resultsErrorAtom);
    useAtom(this.needsGuidAtom);
    useAtom(this.screeningErrorAtom);

    return this;
  };

  private get ScreeningResponseGuid(): string {
    return this.atomStore.get(this.screeningResponseGuidAtom);
  }

  private get ScreeningResponseGuids(): Array<string> {
    return this.atomStore.get(this.screeningResponseGuidsAtom);
  }

  public get ScreeningGuid(): string {
    return this.atomStore.get(this.screeningGuidAtom);
  }

  public get DueScreenings(): Array<any> {
    return this.atomStore.get(this.dueScreeningsAtom);
  }

  public get Workflow(): string {
    return this.atomStore.get(this.workflowAtom);
  }

  public get ScreeningResults(): Array<any> {
    return this.atomStore.get(this.screeningResultsAtom);
  }

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  public get HighestScoreGuid(): string {
    return this.atomStore.get(this.highestScoreAtom);
  }

  public get ResultsHasError(): boolean {
    return this.atomStore.get(this.resultsErrorAtom);
  }

  public get NeedsGuid(): boolean {
    return this.atomStore.get(this.needsGuidAtom);
  }

  public get ScreeningHasError(): boolean {
    return this.atomStore.get(this.screeningErrorAtom);
  }

  public get DueScreeningsIndex(): number {
    return this.atomStore.get(this.dueScreeningsIndexAtom);
  }

  public get ScreeningForm(): IScreeningTakerModuleStore {
    if (this.screeningForm) return this.screeningForm;

    const FormStore = ScreeningFormModuleStoreFactory.get(this.ScreeningGuid);

    if (!FormStore) return null;

    this.screeningForm = FormStore.create(this.complete);

    return this.screeningForm;
  }

  private filterInvalidScreenigs = (screenings) => {
    const validScreeningGuids = Object.values(Screenings);
    const validDueScreenings = screenings?.filter((x) => validScreeningGuids.includes(x.screeningGuid));

    return !validDueScreenings ? [] : validDueScreenings;
  };

  private nextScreeningGuid = () => {
    if (this.DueScreenings?.length > 0 && this.DueScreeningsIndex < this.DueScreenings?.length) {
      return this.DueScreenings[this.DueScreeningsIndex]?.screeningGuid;
    }

    return null;
  };

  public removeOptionalScreenings = () => {
    const requiredScreenings = this.DueScreenings.slice(this.DueScreeningsIndex).filter(
      (s) => s.screeningType !== ScreeningType.OPTIONAL,
    );
    this.atomStore.set(this.dueScreeningsAtom, requiredScreenings);
    this.atomStore.set(this.dueScreeningsIndexAtom, 0);
    this.atomStore.set(this.screeningGuidAtom, this.nextScreeningGuid());

    this.next();
  };

  public startOptionalQuestions = () => {
    this.resetScreening();
    this.atomStore.set(this.workflowAtom, WorkFlowTypes.QUESTIONNAIRE);
    browserHistory.replace(urlcat(ASSESSMENT, { screeningGuid: this.ScreeningGuid }));
    this.startScreening();
  };

  private next = async () => {
    const currentScreeningStatusIsOptional = this.DueScreenings
      ? this.DueScreenings[this.DueScreeningsIndex]?.screeningType === ScreeningType.OPTIONAL
      : null;

    if (this.ScreeningGuid && !currentScreeningStatusIsOptional) {
      this.resetScreening();
      this.atomStore.set(this.workflowAtom, WorkFlowTypes.QUESTIONNAIRE);
      browserHistory.replace(urlcat(ASSESSMENT, { screeningGuid: this.ScreeningGuid }));
      this.startScreening();
    } else if (currentScreeningStatusIsOptional) {
      this.atomStore.set(this.workflowAtom, WorkFlowTypes.OPTIONALTRANSITION);
      browserHistory.replace(urlcat(ASSESSMENT, { screeningGuid: 'additionalquestions' }));
    } else if (this.ScreeningResponseGuids?.length > 0) {
      this.resetScreening();
      this.atomStore.set(this.screeningGuidAtom, null);
      this.atomStore.set(this.workflowAtom, WorkFlowTypes.RESULTS);
      browserHistory.replace(urlcat(ASSESSMENT, { screeningGuid: 'results' }));
      await this.hydrateResults();
    } else {
      browserHistory.push(HOME);
    }
  };

  private startScreening = async () => {
    if (!this.userAccessor.User.UserId) return;
    if (!this.ScreeningGuid) return;
    this.IsLoading = true;
    this.atomStore.set(this.screeningErrorAtom, false);

    const screeningResponseGuid = await this.screeningResponseApiDataService.startScreeningResponse({
      userId: this.userAccessor.User.UserId,
      screeningGuid: this.ScreeningGuid,
    });

    if (!screeningResponseGuid) {
      this.atomStore.set(this.screeningErrorAtom, true);
    } else {
      this.atomStore.set(this.screeningResponseGuidAtom, screeningResponseGuid);
    }

    this.IsLoading = false;
  };

  private resetScreening = () => {
    if (this.screeningForm) {
      delete this.screeningForm;
      this.screeningForm = null;
    }
  };

  private resetAtoms = () => {
    this.atomStore.set(this.dueScreeningsAtom, []);
    this.atomStore.set(this.screeningResponseGuidsAtom, []);
    this.atomStore.set(this.screeningResponseGuidAtom, null);
    this.atomStore.set(this.screeningResultsAtom, []);
    this.atomStore.set(this.highestScoreAtom, null);
    this.atomStore.set(this.resultsErrorAtom, false);
    this.atomStore.set(this.needsGuidAtom, null);
    this.atomStore.set(this.screeningErrorAtom, false);
  };

  private hydrateResults = async () => {
    const { response: screeningScores, hasError } = await this.screeningScoresApiDataService.getScreeningScores(
      {
        screeningResponseGuids: this.ScreeningResponseGuids.join(','),
        userId: this.userAccessor.User.UserId,
      },
      3,
    );

    if (hasError || screeningScores?.length !== this.ScreeningResponseGuids.length) {
      this.atomStore.set(this.resultsErrorAtom, true);
      return;
    }

    this.atomStore.set(this.resultsErrorAtom, false);

    if (screeningScores?.length > 0) {
      const resourceGuids = [Screenings.YOUTH_NEEDS, Screenings.ADULT_PRAPARE_SDOH, Screenings.ADULT_NEEDS, Screenings.ADULT_EP_PRAPARE_SDOH];
      const highestScore = [...screeningScores]?.sort((a, b) => b.score - a.score).find((x) => x.hasUrgentNeed);
      const urgentNeeds = screeningScores.find((x) => resourceGuids.includes(x.screeningGuid) && x.hasUrgentNeed);

      this.atomStore.set(this.highestScoreAtom, highestScore?.screeningGuid);
      this.atomStore.set(this.needsGuidAtom, urgentNeeds?.screeningGuid);
    }

    this.atomStore.set(this.screeningResultsAtom, screeningScores);
  };

  public initialize = async () => {
    if (!this.userAccessor.User.UserId) return;

    this.resetAtoms();

    let dueScreenings = await this.screeningScheduleApiDataService.getScreeningSchedule({
      userId: this.userAccessor.User.UserId,
    });

    dueScreenings = this.filterInvalidScreenigs(dueScreenings.screenings);

    this.atomStore.set(this.dueScreeningsAtom, dueScreenings);

    this.atomStore.set(this.dueScreeningsIndexAtom, 0);
    let screeningGuid = this.nextScreeningGuid();

    if (!screeningGuid) {
      const match = matchPath(browserHistory.location.pathname, ASSESSMENT);
      screeningGuid = match?.params?.screeningGuid;
    }

    this.atomStore.set(this.screeningGuidAtom, screeningGuid);
    await this.next();
  };

  public acknowledgeError = async () => {
    if (!this.ScreeningResponseGuid) {
      await this.startScreening();
    }
  };

  public complete = async (answers) => {
    this.atomStore.set(this.screeningErrorAtom, false);

    const hasCompletedScreening = await this.screeningResponseApiDataService.completeScreeningResponse({
      userId: this.userAccessor.User.UserId,
      screeningResponseGuid: this.ScreeningResponseGuid,
      answers,
    });

    if (!hasCompletedScreening) {
      this.atomStore.set(this.screeningErrorAtom, true);
      return;
    }

    this.atomStore.set(this.dueScreeningsIndexAtom, this.DueScreeningsIndex + 1);

    if (!this.ScreeningResponseGuids.includes(this.ScreeningResponseGuid)) {
      const screeningResponseGuids = this.ScreeningResponseGuids.concat([this.ScreeningResponseGuid]);
      this.atomStore.set(this.screeningResponseGuidsAtom, screeningResponseGuids);
      this.atomStore.set(this.screeningResponseGuidAtom, null);
    }

    this.atomStore.set(this.screeningGuidAtom, this.nextScreeningGuid());
    await this.next();
  };
}
