import YouthPHQ2 from 'Core_Pages/Screening/ScreeningImplementations/YouthPHQ2/Results/CheckinResult';
import YouthUCLA3 from 'Core_Pages/Screening/ScreeningImplementations/YouthUCLA3/Results/CheckinResult';
import YouthNeeds from 'Core_Pages/Screening/ScreeningImplementations/YouthNeeds/Results/CheckinResult';
import AdultUCLA3 from 'Core_Pages/Screening/ScreeningImplementations/AdultUCLA3/Results/CheckinResult';
import AdultPHQ4 from 'Core_Pages/Screening/ScreeningImplementations/AdultPHQ4/Results/CheckinResult';
import AdultNeeds from 'Core_Pages/Screening/ScreeningImplementations/AdultNeeds/Results/CheckinResult';
import AdultPrapareSdoh from 'Core_Pages/Screening/ScreeningImplementations/AdultPrapareSdoh/Results/CheckinResult';
import AdultEPPrapareSdoh from 'Core_Pages/Screening/ScreeningImplementations/AdultEPPrapareSdoh/Results/CheckinResult';
import AdultWellBeing from 'Core_Pages/Screening/ScreeningImplementations/AdultWellBeing/Results/CheckinResult';
import { Screenings } from 'Core_Pages/Screening/enumerations/enums';

export const Forms = {
  [Screenings.YOUTH_UCLA3]: YouthUCLA3,
  [Screenings.YOUTH_PHQ2]: YouthPHQ2,
  [Screenings.YOUTH_NEEDS]: YouthNeeds,
  [Screenings.ADULT_UCLA3]: AdultUCLA3,
  [Screenings.ADULT_PHQ4]: AdultPHQ4,
  [Screenings.ADULT_NEEDS]: AdultNeeds,
  [Screenings.ADULT_PRAPARE_SDOH]: AdultPrapareSdoh,
  [Screenings.ADULT_EP_PRAPARE_SDOH]: AdultEPPrapareSdoh,
  [Screenings.ADULT_WELLBEING]: AdultWellBeing,
};
