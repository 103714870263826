import { AnswerTypes } from 'Stories/components/Form/answerTypes';
import { PrapareQuestionType } from './prapareQuestionType';

export const questions = [
  {
    name: '50d1f854-f5ae-4393-addd-c8997191ec59',
    textId: 'screenings.form.adult-prapare.question-1',
    questionType: PrapareQuestionType.YESNO,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '2d1fa7f5-36b6-47be-8052-0756c267e06b',
        textId: 'common.yes',
      },
      {
        name: '25dc3171-c26a-46b6-bc65-9b4d85e6079a',
        textId: 'common.no',
      },
      {
        name: '264ec318-b176-4eec-ada5-579ec9b2d2d8',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '1e8e1ae6-4864-4091-863a-de8184db4408',
    textId: 'screenings.form.adult-prapare.question-2',
    questionType: PrapareQuestionType.MULTISELECT,
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: 'd909ecfd-639a-431a-9b2d-e6df74845f43',
        textId: 'demographics.american-indian-alaskan-native',
      },
      {
        name: 'c0917e92-b65b-42e5-9625-3249dd104a4b',
        textId: 'demographics.asian',
      },
      {
        name: '2c9070e9-4109-4070-b9fe-57c7b33320c9',
        textId: 'demographics.black-african-american',
      },
      {
        name: 'a1e5bd34-d925-4388-aa27-3908887dbfee',
        textId: 'demographics.pacific-islander',
      },
      {
        name: '77779e7d-71cf-478d-829d-1093d37f1091',
        textId: 'demographics.white',
      },
      {
        name: 'bb0bb287-6768-4fbf-9847-75e9e3569239',
        textId: 'common.other',
      },
      {
        name: 'd61e8920-61db-42e8-9f28-497f038cfa1c',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: 'b5845c9b-2e7c-4f30-8217-4bae0a570ea7',
    textId: 'screenings.form.adult-prapare.question-3',
    questionType: PrapareQuestionType.YESNO,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: 'f1182777-9020-4876-8f02-043ea1a1bc28',
        textId: 'common.yes',
      },
      {
        name: 'cc72ead0-23f0-4795-bd12-82c787b90212',
        textId: 'common.no',
      },
      {
        name: '3d2cddc7-3ebf-43b5-92b1-9ea19bda866d',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '938e61c3-715c-4986-9fd9-1a538695f0fa',
    textId: 'screenings.form.adult-prapare.question-4',
    questionType: PrapareQuestionType.YESNO,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '1eea9006-5ca4-4fa5-8f59-4875ceafdd79',
        textId: 'common.yes',
      },
      {
        name: '70c65bda-c123-43ce-ad86-40d5de74660e',
        textId: 'common.no',
      },
      {
        textId: 'common.skip',
        name: 'e5c516d0-c53f-43ce-a350-f0d364182a72',
      },
    ],
  },
  {
    name: '69e21a18-92f7-490f-83e0-1828fed3ad15',
    textId: 'screenings.form.adult-prapare.question-5',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '10e891d5-38bf-4df5-8b71-24fcc73f5fb4',
        textId: 'common.english',
      },
      {
        name: '5dfe751a-624c-4421-b7da-a4af3a27ad09',
        textId: 'common.other',
      },
      {
        name: '17438707-5eaf-47e9-b9ea-6ff16543cb8e',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: 'eb16b3e6-c3f6-48a1-b2aa-89f761116b33',
    textId: 'screenings.form.adult-prapare.question-6',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '449a8902-688a-4b5b-9723-e1f9ee905dec',
        textId: 'common.number-one',
      },
      {
        name: 'e08049a9-3489-4369-bdf4-f12abdb960a2',
        textId: 'common.number-two',
      },
      {
        name: 'e827c5c0-5122-4618-943c-4aec52765d67',
        textId: 'common.number-three',
      },
      {
        name: 'ec15015d-068b-4117-a39d-ab017c5e48d5',
        textId: 'common.number-four',
      },
      {
        name: '5857683d-4846-4c29-af41-bab73aa7dac4',
        textId: 'common.number-five',
      },
      {
        name: 'be681c01-d179-4950-bf89-d19898f8f17c',
        textId: 'common.number-six',
      },
      {
        name: 'c79ca0bf-de6d-4e2c-af3f-e0d70df6892c',
        textId: 'common.number-seven',
      },
      {
        name: '811530e5-8bad-4eea-86b5-cdaebc824e61',
        textId: 'common.number-eight',
      },
      {
        name: 'e22d049f-0116-4239-8f91-98a3a99d60e6',
        textId: 'common.number-nine',
      },
      {
        name: '9d4b2dff-cd12-44e6-9031-07f5349c4f1c',
        textId: 'common.number-ten-plus',
      },
      {
        name: '361a6c5c-caa6-4359-8ee9-35d35e155fb5',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '291871bc-5bac-411c-aae6-017b4c073a3a',
    textId: 'screenings.form.adult-prapare.question-7',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '18b0d5da-6602-46d3-8e4e-3844560fc08e',
        textId: 'demographics.have-housing',
      },
      {
        name: 'b7dea63b-b0c3-4eca-b1ea-428eb1ba50a7',
        textId: 'demographics.do-not-have-housing',
      },
      {
        name: '2da66208-122e-4d93-866d-5b2010a7720b',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '8d0d100a-9d74-4e66-a4b6-c719c59a5f5b',
    textId: 'screenings.form.adult-prapare-ep.question-8',
    questionType: PrapareQuestionType.YESNO,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        textId: 'common.yes',
        name: '0a2d41e0-1a74-4522-9ec0-6ac4c31a671a',
      },
      {
        textId: 'common.no',
        name: '1253c31d-8ea2-4b8c-adfc-bf9e63601c82',
      },
    ],
  },
  {
    name: '0b7d6a07-206e-40dc-ba53-2d37dc0c29ca',
    textId: 'screenings.form.adult-prapare.question-9',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '7acafd91-7d24-406e-bbc2-8d86dc58dd45',
        textId: 'demographics.education.less-than-high-school',
      },
      {
        name: '3d6706c1-32e7-4f18-b2c9-5345946d2207',
        textId: 'demographics.education.high-school-or-ged',
      },
      {
        name: '9eabac36-1919-45f9-87e4-f8c0bc56aaee',
        textId: 'demographics.education.more-than-high-school',
      },
      {
        name: '0acca8c9-5ddd-48ed-84fd-7c9f1bc072eb',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '9a33fef4-1510-468b-8b8f-bc48e8ecd7f2',
    textId: 'screenings.form.adult-prapare.question-10',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '22914e75-39a0-4b39-b331-70e29a33bd71',
        textId: 'demographics.employment.unemployed',
      },
      {
        name: 'eb94074c-1bac-4bfa-a206-b1c731658634',
        textId: 'demographics.employment.part-time-or-temporary',
      },
      {
        name: '30c96992-17aa-47d4-8f99-a8530ce44718',
        textId: 'demographics.employment.full-time',
      },
      {
        name: '405d45b9-2d61-45a3-81c0-e8c292c411b6',
        textId: 'demographics.employment.not-seeking-work',
      },
      {
        name: 'becf978b-f4d1-4aeb-945f-d69db33adfda',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '17545368-87de-49b4-a5dc-7ac28f68b0cc',
    textId: 'screenings.form.adult-prapare.question-11',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: 'fabfdfc6-0072-4ba4-8cb8-a77d6604696e',
        textId: 'demographics.insurance.none-uninsured',
      },
      {
        name: '67bd8c46-1692-468e-9635-1a685b2b8579',
        textId: 'demographics.insurance.medicaid',
      },
      {
        name: '00e3f9e1-d910-4421-8a62-b198ab11ee69',
        textId: 'demographics.insurance.chip-medicaid',
      },
      {
        name: '7e360c7e-0598-47b9-b071-a08e074a7bf8',
        textId: 'demographics.insurance.medicare',
      },
      {
        name: '85c76f68-9320-4760-8ba6-8caf3af6f1d3',
        textId: 'demographics.insurance.non-chip-public',
      },
      {
        name: '01f640c8-c4b8-4dca-82c3-458bea826e7f',
        textId: 'demographics.insurance.chip-public',
      },
      {
        name: '0ae5b243-cd56-4ce8-a794-61fd9774b00d',
        textId: 'demographics.insurance.private-insurance',
      },
      {
        name: '7cb76306-c4cc-49dc-aa48-b0d6a8084bb4',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '33fe1e66-b69e-4879-83ac-a190a76b812a',
    textId: 'screenings.form.adult-prapare-ep.question-12',
    questionType: PrapareQuestionType.INCOMESLIDER,
    answerType: AnswerTypes.SLIDER,
    options: [
      {
        name: '22799f2c-8460-46e7-89e9-ddd254ff8d2f',
        textId: 'demographics.income.variable-amount',
      },
      {
        name: '9a4c42fe-d92f-4920-8a8a-f3c340fe54ad',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: 'e6a19d82-6911-43e5-a18d-5cad79eed6ab',
    textId: 'screenings.form.adult-prapare.question-13',
    questionType: PrapareQuestionType.MULTISELECT,
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: '942b974c-ca42-473d-a145-ee9cbdda366f',
        textId: 'resource.food',
      },
      {
        name: '6fad7ab5-b73a-40b6-b7a5-d72734d1cdbd',
        textId: 'resource.clothing',
      },
      {
        name: '14d31ed8-3197-4966-8661-5ce81afbc3c1',
        textId: 'resource.utilities',
      },
      {
        name: '3b74bde4-5ad6-4488-a14b-4930d236941f',
        textId: 'resource.childcare',
      },
      {
        name: '67587dd9-0a96-40c3-af2a-f4b561b9cf79',
        textId: 'resource.medicine-or-healthcare',
      },
      {
        name: 'b6c85b60-1395-4f06-b2e5-23701f0419c8',
        textId: 'resource.phone',
      },
      {
        name: 'cd031480-b246-493c-a225-1711a131336e',
        textId: 'common.other',
      },
      {
        name: 'b11a84a0-d9f1-4954-83bf-7c0456ee5337',
        textId: 'common.no',
      },
    ],
  },
  {
    name: '5111fd45-5d1d-417b-8ed8-4c1f30210bd8',
    textId: 'screenings.form.adult-prapare.question-14',
    questionType: PrapareQuestionType.MULTISELECT,
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: '387ed9cb-8943-4aee-8edb-8cc2dfce8dd0',
        textId: 'yes.medical-appointments',
      },
      {
        name: 'fb9d1629-8f82-461f-bceb-f97fbcdcc1fe',
        textId: 'yes.non-medical',
      },
      {
        name: 'd8a926f4-da54-402f-bd44-b2f03efdf629',
        textId: 'common.no',
      },
    ],
  },
  {
    name: 'e1d49d81-ef13-4cbf-a85a-b26f3e350e24',
    textId: 'screenings.form.adult-prapare.question-15',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: 'a73111f5-6df7-4640-ab3b-ccaca465fed2',
        textId: 'common.duration.less-than-once-a-week',
      },
      {
        name: 'c4687a0b-451d-4780-9ec4-cdd25adaf882',
        textId: 'common.duration.one-or-two-times-a-week',
      },
      {
        name: 'cc631ba1-bc54-41cb-aef6-ff683873e00f',
        textId: 'common.duration.three-to-four-times-a-week',
      },
      {
        name: '87466cd4-e58a-453a-9a01-415e8fc6a961',
        textId: 'common.duration.six-or-more-times-a-week',
      },
      {
        name: '7855e3c7-e905-4bd6-b629-903f9cde6b68',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '7f759216-616f-4f77-a0f5-4806c4a83f87',
    textId: 'screenings.form.adult-prapare.question-16',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: 'ffed49db-2b1e-48e5-9625-6818998a0f7f',
        textId: 'common.sentiment.not-at-all',
      },
      {
        name: 'cdd0e7da-93e4-49ff-963e-341e1cae8a18',
        textId: 'common.sentiment.a-little-bit',
      },
      {
        name: '277b5d98-c14b-469b-b769-f93862d86fa5',
        textId: 'common.sentiment.somewhat',
      },
      {
        name: '1ff03294-8a56-4c60-8007-ca5eae546d9f',
        textId: 'common.sentiment.quite-a-bit',
      },
      {
        name: '6eb9c782-09b1-45a6-bf87-a2c55fe8d817',
        textId: 'common.sentiment.very-much',
      },
      {
        name: '82933c1e-dc76-4125-ac42-c7f7d642d0ca',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '6cdea59b-88ab-496c-a871-0bfd01ae2e46',
    textId: 'screenings.form.adult-prapare.question-17',
    questionType: PrapareQuestionType.YESNO,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: 'f9b06631-613d-4f8d-b204-8c9c01889c7e',
        textId: 'common.yes',
      },
      {
        name: '959e1fae-61fc-47a0-af0e-f3d50181d95a',
        textId: 'common.no',
      },
      {
        name: 'ce7fc106-d903-4650-9314-2d22c0ca186d',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: 'c84322e2-1aa1-4663-b538-93229de75895',
    textId: 'screenings.form.adult-prapare.question-18',
    questionType: PrapareQuestionType.YESNO,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: 'df7a1396-8941-40c2-a213-e820b520d3a2',
        textId: 'common.yes',
      },
      {
        name: 'b32b9b1a-2223-484b-b8ed-29f816e949fb',
        textId: 'common.no',
      },
      {
        name: '7929069c-c93b-495e-a99c-1de1f59bca66',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '182a175e-1f5e-43b6-8751-eb8c41fd6fb5',
    textId: 'screenings.form.adult-prapare.question-19',
    questionType: PrapareQuestionType.YESNO,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '8d7d6d1d-be53-4415-b612-2f1f87bd3510',
        textId: 'common.yes',
      },
      {
        name: '710f93bc-23e0-4972-a3e1-f5444d4cee1b',
        textId: 'common.no',
      },
      {
        name: '6351a818-7ddd-4d43-9e4f-3b1197daf9be',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '5eacad4b-869c-403d-b7aa-225bb1a51fbf',
    textId: 'screenings.form.adult-prapare.question-20',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '90a41ef1-376a-4b7e-aa91-41fd4e638b1c',
        textId: 'common.yes',
      },
      {
        name: '4358e640-f183-4eaa-9f97-a96bd4e56bff',
        textId: 'common.no',
      },
      {
        name: '13219fa0-47fb-4988-b43f-08abf89bbeb8',
        textId: 'common.unsure',
      },
      {
        name: '6eca415a-a017-4acc-adcf-43d21c7d8f46',
        textId: 'demographics.relationship.have-not-had-a-partner',
      },
      {
        name: '8db2677a-ccf3-4be1-92e6-d7342ec34214',
        textId: 'common.skip',
      },
    ],
  },
  {
    name: '5010f30a-cefa-41a6-aa6e-42bdd46ec741',
    textId: 'screenings.form.adult-prapare-ep.question-21',
    questionType: PrapareQuestionType.SINGLESELECT,
    answerType: AnswerTypes.RADIO,
    options: [
      {
        name: '6d880e32-2d5b-4bd2-af53-1c97af98ffae',
        textId: 'common.yes',
      },
      {
        name: 'c99eb0a1-acf9-48aa-979c-89de51f649ce',
        textId: 'common.no',
      },
    ],
  },
];
