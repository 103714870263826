import Description from './Description';
import Header from './Header';
import Resources from './Resources';
import { ResultVariantTypes } from 'Core_Pages/Screening/Results/Result/ResultContext';

const AdultEPPrapareSdoh = {
  [ResultVariantTypes.Header]: Header,
  [ResultVariantTypes.Description]: Description,
  [ResultVariantTypes.Resources]: Resources,
};

export default AdultEPPrapareSdoh;
