import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Core_Helpers/Singleton';
import { IStateStore } from 'Core_Helpers/IStateStore';
import getDateTimeOverride from 'Core_Helpers/localStorage/getDateTimeOverride/getDateTimeOverride';
import { addDays } from 'Helpers/DateHelper';

const CUTOFFDATE = '2025-01-10';
const ACKNOWLEDGEMENTCOUNTTHRESHOLD = 2;
const DAYSBETWEENSHOWING = 1;

const ACK_STORAGE_KEY = 'holiday_content_winter_2024_ack_count';
const LAST_SEEN_DATE_STORAGE_KEY = 'holiday_content_winter_2024_latest_seen_date';

export class HolidayContentModalModuleStore extends Singleton implements IStateStore {
  private atomStore = getDefaultStore();
  private isOpenAtom;
  private hasBeenShown = false;

  constructor() {
    super();
    this.isOpenAtom = atom(false);
  }

  public Use = () => {
    useAtom(this.isOpenAtom);

    return this;
  };

  public get IsOpen(): any {
    return this.atomStore.get(this.isOpenAtom);
  }

  public close() {
    this.atomStore.set(this.isOpenAtom, false);
    this.hasBeenShown = true;
  }

  public open() {
    this.atomStore.set(this.isOpenAtom, true);
  }

  public acknowledge() {
    const dateTimeOverride = getDateTimeOverride();
    const today = dateTimeOverride ? new Date(dateTimeOverride) : new Date();

    const ackCount = JSON.parse(localStorage.getItem(ACK_STORAGE_KEY)) ?? 0;

    localStorage.setItem(ACK_STORAGE_KEY, (ackCount + 1).toString());
    localStorage.setItem(LAST_SEEN_DATE_STORAGE_KEY, today.toString());
  }

  public readyForDisplay() {
    return (
      !this.hasBeenShown &&
      this.getAcknowledgmentCount() < ACKNOWLEDGEMENTCOUNTTHRESHOLD &&
      this.isDateBeforeCutoff() &&
      this.isPastDaysBetweenShowingThreshold()
    );
  }

  private getAcknowledgmentCount() {
    const count = JSON.parse(localStorage.getItem(ACK_STORAGE_KEY));
    return count;
  }

  private isPastDaysBetweenShowingThreshold() {
    const lastSeen = new Date(localStorage.getItem(LAST_SEEN_DATE_STORAGE_KEY));

    if (!lastSeen) {
      return true;
    }

    const dateTimeOverride = getDateTimeOverride();
    const today = dateTimeOverride ? new Date(dateTimeOverride) : new Date();

    const thresholdDate = addDays(lastSeen, DAYSBETWEENSHOWING);

    return today > thresholdDate;
  }

  private isDateBeforeCutoff() {
    const dateTimeOverride = getDateTimeOverride();
    const today = dateTimeOverride ? new Date(dateTimeOverride) : new Date();
    return today < new Date(CUTOFFDATE);
  }
}
